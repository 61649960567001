// import React from "react";
import { lazy } from "@loadable/component";

const Home = lazy(() => import("./pages/home/home"));
const Solution = lazy(() => import("./pages/Solution/solution"));
const Product = lazy(() => import("./pages/Product/product"));
const ContactUs = lazy(() => import("./pages/ContactUs/contactus"));
const SolutionDetails = lazy(() => import("./pages/Solution/SolutionDetails"));
const ProductDetails = lazy(() => import("./pages/Product/ProductDetail"));

const PrivacyPolicy = lazy(() => import("./pages/privacyPolicy/privacyPolicy"));
const About = lazy(() => import("./pages/about/About"));

const routes = [
  { path: "/", name: "Home", component: Home, exact: true },
  { path: "/home", name: "Home", component: Home, exact: true },
  { path: "/solution", name: "Solution", component: Solution, exact: true },
  // { path: "/product", name: "Product", component: Product, exact: true },
  { path: "/product/:type", name: "Product", component: Product, exact: true },
  {
    path: "/about-us",
    name: "About Us",
    component: About,
    exact: true,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
    exact: true,
  },
  {
    path: "/solution/details/",
    name: "Solution Details",
    component: SolutionDetails,
    exact: true,
  },
  {
    path: "/solution/details/:type",
    name: "Solution Details",
    component: SolutionDetails,
    exact: true,
  },
  {
    path: "/product/:type/",
    name: "Product Detail",
    component: ProductDetails,
    exact: true,
  },
  {
    path: "/product/:type/:key",
    name: "Product Detail",
    component: ProductDetails,
    exact: true,
  },
  // {
  //   path: "/:type/",
  //   name: "Product Detail",
  //   component: ProductDetails,
  //   exact: true,
  // },
  // {
  //   path: "/:type/:key",
  //   name: "Product Detail",
  //   component: ProductDetails,
  //   exact: true,
  // },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: PrivacyPolicy,
    exact: true,
  },

  // { path: "/gallery", name: "Gallery", component: Gallery, exact: true },
  // { path: "/about", name: "About", component: About, exact: true },
  // { path: "/news", name: "News", component: News, exact: true },
  // { path: "/projects", name: "Projects", component: Projects, exact: true },
  // { path: "/story", name: "Story", component: Story, exact: true },
];

export default routes;
